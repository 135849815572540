/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/sweetalert2@11.4.24/dist/sweetalert2.all.min.js
 * - /npm/sweetalert2@11.4.24/dist/sweetalert2.all.min.js
 * - /npm/sweetalert2@11.4.24/dist/sweetalert2.min.js
 * - /npm/sweetalert2@11.4.24/dist/sweetalert2.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
